import {
  Container,
  Divider,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { NameLogoBlue } from "../assests";
import {
  CommonErrorModal,
  CommonLoading,
  PurchasePackageCard,
} from "../components";
import { CheckCouponReturnDto, PurchaseService } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getUserProfile } from "../redux/authSlice";
import { UseMetaConversation } from "../hooks";
import { MetaEventNames } from "../utils/constants";
import { paymentClient } from "../apiClient/apiClient";

const PackageOptions: FC = () => {
  const { profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [discountCouponDetail, setDiscountCouponDetail] =
    useState<CheckCouponReturnDto>({
      id: "",
      discount_percent: 0,
      can_be_used_by_pricing_option_ids: [],
    });
  const [modalError, setModalError] = useState<boolean>(false);
  const [localLoading, setLocalLoading] = useState<boolean>(false);
  const [packages, setPackages] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [hasMorePackages, setHasMorePackages] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  useEffect(() => {
    fetchPackages(currentPage);
    UseMetaConversation({
      event_name: MetaEventNames.pricingPackagesWithLogin,
      user_id: profile.id,
      custom_data: { url: "/show-subscription-packages" },
    });
    if (searchParams.get("coupon")) {
      checkCouponValidation(searchParams.get("coupon") as string);
    }
  }, []);

  const fetchPackages = async (page = 1, isLoadMore = false) => {
    try {
      if (!isLoadMore) {
        setLoading(true);
      }
      const response = await paymentClient().get(
        `/pricingPackage?perPage=30&page=${page}&name=`
      );

      let newPackages = response.data.data.filter((e:any) => e.type != "weekly" && e.type != "life_time")
      setPackages((prevPackages) => [...prevPackages, ...newPackages]);

      if (response.data.data.length < 30) {
        setHasMorePackages(false);
      }
    } catch (error) {
      console.error("Error fetching packages:", error);
    } finally {
      if (isLoadMore) {
        setLoadingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  const loadMorePackages = () => {
    if (hasMorePackages) {
      setLoadingMore(true);
      const nextPage = currentPage + 1;
      fetchPackages(nextPage, true);
      setCurrentPage(nextPage);
    }
  };

  const checkCouponValidation = async (couponCode: string) => {
    try {
      const couponResponse =
        await PurchaseService.checkCouponCodePurchaseCheckCouponCodePost(
          couponCode
        );
      setDiscountCouponDetail(couponResponse);
    } catch (error) {
      setDiscountCouponDetail({
        id: "",
        discount_percent: 0,
        can_be_used_by_pricing_option_ids: [],
      });
    }
  };

  useEffect(() => {
    if (profile.is_user_premium) navigate("/home");
  }, [profile, navigate]);

  const cardOnclick = async (id: string) => {
    try {
      if (discountCouponDetail.id === "") {
        navigate(`/package-payment/${id}`);
      } else if (
        !discountCouponDetail.can_be_used_by_pricing_option_ids?.includes(id)
      ) {
        setModalError(true);
      } else {
        if (discountCouponDetail.discount_percent === 100) {
          setLocalLoading(true);
          const premiumOperation =
            await PurchaseService.makePremiumWithoutCreditCardPurchaseMakePremiumWithoutCreditCardPost(
              discountCouponDetail.id,
              id
            );
          setLocalLoading(false);
          if (premiumOperation.is_request_successful) {
            dispatch(getUserProfile());
            navigate("/my-subscription");
          }
        } else {
          navigate(`/package-payment/${id}?coupon=${discountCouponDetail.id}`);
        }
      }
    } catch (error) {
      setLocalLoading(false);
      console.error("Error during package selection", error);
    }
  };

  return (
    <>
      <img
        src={NameLogoBlue}
        alt=""
        style={{ marginTop: "20px", marginBottom: "20px", cursor: "pointer" }}
        onClick={() => navigate("/home")}
      />
      <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
      <CommonErrorModal
        isOpen={modalError}
        title="İndirim Kodu Bu Paket İçin Kullanılamıyor"
        text="Girdiğiniz indirim kodu seçtiğiniz pakette kullanılmamaktadır. Lütfen farklı bir paket seçin."
        buttonText="Premium Seçeneklerini Gör"
        onButtonFunc={() => setModalError(false)}
      />
      <CommonLoading loading={loading || localLoading} />
      <Container
        maxWidth="lg"
        style={{ marginTop: "24px", marginBottom: "24px" }}
      >
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={4}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {/* Header Section */}
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: "8px 16px",
                background: "#FFCE00",
                borderRadius: "16px",
                width: "fit-content",
                marginBottom: "16px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  fontSize: "14px",
                  lineHeight: "22px",
                  color: "#171725",
                }}
              >
                Premium
              </Typography>
            </div>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "40px",
                lineHeight: "48px",
                color: "#171725",
              }}
            >
              Sınırsız İçeriğe Geç
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#78828A",
                marginTop: "16px",
              }}
            >
              Bilimsel ve güvenilir bilgiye ulaşmak
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "24px",
                color: "#78828A",
              }}
            >
              için en iyi planı seçin
            </Typography>
          </Grid>
          {/* Packages List */}
          {packages.length > 0 &&
            packages.map((pkg) => (
              <Grid item xs={4} sm={8} md={4} key={pkg.id}>
                <PurchasePackageCard
                  id={pkg.id}
                  name={pkg.name}
                  price={pkg.price}
                  is_highlighted={pkg.isHighlighted}
                  onClick={cardOnclick}
                />
              </Grid>
            ))}
          {/* "Diğer Paketleri Göster" Button */}
          {hasMorePackages && (
            <Grid
              item
              xs={4}
              sm={8}
              md={12}
              style={{
                display: "flex",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loadingMore ? (
                <CircularProgress />
              ) : (
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: "#2574FF",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={loadMorePackages}
                >
                  Diğer Paketleri Göster
                </Typography>
              )}
            </Grid>
          )}
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              display: "flex",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "26px",
                color: "#9CA4AB",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => navigate("/home")}
            >
              Daha Sonra Satın Alacağım
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default PackageOptions;

import { Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CommonButton from "./CommonButton";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { UserService } from "../client";
import { useNavigate } from "react-router";
import { contentClient } from "../apiClient/apiClient";

const ParentSchoolModuleProgress: FC = () => {
  const containerStyle = {
    backgroundColor: "#F9F9F9",
    borderRadius: "24px",
    padding: "35px 30px",
  };

  const navigate = useNavigate()
  const [localLoading, setLocalLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [remaining, setRemaining] = useState<number>(0)

  useEffect(() => {
    fetchPsProgress()
  }, [])

  const fetchPsProgress = async() => {
    try {
      setLocalLoading(true)
      const progress = await contentClient().get('/progressLog/percent?type=parentSchool')
      const { total, remaining } = progress.data.data
      setTotal(total)
      setRemaining(remaining)
    } catch (error) {
      
    } finally {
      setLocalLoading(false)
    }
  }
  

  const getCertificate = async() => {
    try {
      setLocalLoading(true)
      const encryptedId = await UserService.getEncryptedUserIdForCertificateUserGetEncryptedUserIdForCertificateGet()
      navigate(`/certificate/${encryptedId.encrypted_user_id_for_certificate}`)
      setLocalLoading(false)
    } catch (error) {
      setLocalLoading(false)
    }
  }

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Grid item xs={4} sm={8} md={12}>
        {total - remaining <= 0 ? (
          <CircularProgressbarWithChildren
            value={100}
            styles={buildStyles({
              pathColor: "#2574FF",
              trailColor: "#E0E0E0",
            })}
            strokeWidth={3}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "17px",
                  lineHeight: "24px",
                  color: "#7D8699",
                }}
              >
                Anne Baba Okulu’nu
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "17px",
                  lineHeight: "24px",
                  color: "#7D8699",
                }}
              >
               başarıyla tamamlayarak bu sertifikayı almaya hak kazandınız.
              </Typography>
            </div>
          </CircularProgressbarWithChildren>
        ) : (
          <CircularProgressbarWithChildren
            value={((total - remaining) / total) * 100}
            styles={buildStyles({
              pathColor: "#2574FF",
              trailColor: "#E0E0E0",
            })}
            strokeWidth={3}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "40px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "24px",
                  color: "#7D8699",
                }}
              >
                Anne Baba Okulu’ndan mezun olmana
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "700",
                  fontSize: "38px",
                  lineHeight: "44px",
                  color: "#2574FF",
                  marginTop: "8px",
                }}
              >
                {total - remaining < 0 ? 0 : remaining }
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "400",
                  fontSize: "15px",
                  lineHeight: "20px",
                  color: "#7D8699",
                }}
              >
                bölüm kaldı
              </Typography>
            </div>
          </CircularProgressbarWithChildren>
        )}
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <CommonButton
          text={remaining == 0 ? "Sertifika Al" : "Devam Et"}
          fontSize="15px"
          customCSS={{
            background: "#2574FF",
            boxShadow: "0px 10px 20px rgba(37, 116, 255, 0.3)",
            borderRadius: "16px",
            padding: "13px 0px",
          }}
          onClick={remaining == 0 ? getCertificate : () => console.log()}
          disabled={localLoading}
        />
      </Grid>
    </Grid>
  );
};

export default ParentSchoolModuleProgress;

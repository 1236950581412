import { FC } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stepper,
  StepConnector,
  Step,
  StepLabel,
} from "@mui/material";
import {
  ModuleAccordionDownArrow,
  ParentSchoolContentTick,
  PlayGreen,
  ParentSolutionContentLocked,
  ReadyToPlay,
  Lock,
} from "../assests";
import { styled } from "@mui/material/styles";
import { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { TrainingSetArticleItem } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { ProgressStatusType } from "../client";
import { IParentSchoolPageSelectedContent, IParentSchoolSelectedArticle } from "../utils/commonModels";
import { doesUserHasAccessToContent } from "../utils/commonFuncs";
import { openBecomePremiumModal } from "../redux/generalControllerSlice";
import { IArticle } from "../apiClient/models/article";

interface Props {
  order: number;
  title: string;
  description?: string;
  articles: Array<IArticle>;
  moduleId: string;
  selectedItem?: IParentSchoolSelectedArticle | null;
  onContentSelected: (item: IParentSchoolSelectedArticle) => void;
  openModuleAccordion: (moduleId:string, isExpanded:boolean) => void
}

const ParentSchoolModuleItem: FC<Props> = ({
  moduleId,
  order = 0,
  title = "",
  description = "",
  articles = [],
  selectedItem,
  onContentSelected,
  openModuleAccordion
}) => {
  const { progress_status_items } = useSelector(
    (state: RootState) => state.log
  );
  const { profile } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<AppDispatch>()

  const currentParentSolutionWathcedLog = progress_status_items.find(
    (e) =>
      e.progress_status_type == ProgressStatusType.PARENT_SCHOOL_MODULE &&
      e.item_id == moduleId
  );

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#DADADA",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: "#0EAD69",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      width: "1px",
      border: 0,
      backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "",
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    borderRadius: "170%",
    justifyContent: "center",
    alignItems: "center",
  }));

  function ColorlibStepIcon(item: IArticle, index: number) {
    const icons: { [index: string]: React.ReactElement } = {
      watched: <img src={ParentSchoolContentTick} alt="" />,
      currentlyPlaying: <img src={PlayGreen} alt="" />,
      locked: <img src={ParentSolutionContentLocked} alt="" />,
      readyToPlay: <img src={ReadyToPlay} alt="" />,
    };

    let userHasAccess = doesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, item?.showToFreePlan ? item.showToFreePlan : false)

    if(!userHasAccess) {
      return (
        <ColorlibStepIconRoot ownerState={{}}>
         {icons[String("locked")]}
        </ColorlibStepIconRoot>
      )
    } else {
      return (
        <ColorlibStepIconRoot ownerState={{}}>
          {
            icons[
              String(
                  selectedItem?.article.id.toString() == item.id.toString()
                    ? "currentlyPlaying"
                    : item.isWatched ? "watched" : "readyToPlay"
              )
            ]
          }
        </ColorlibStepIconRoot>
      )
    }
    
    return (
      <ColorlibStepIconRoot ownerState={{}}>
        {icons["locked"]}
      </ColorlibStepIconRoot>
    );
  }

  const containerStyle = {
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
    border: "0.5px solid #2574FF",
    boxShadow: "none",
    marginBottom:"16px"
  };

  const onContentClicked = (moduleId:string, title:string, article:IArticle) => {
    const userHasAccess = doesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, article?.showToFreePlan ? article.showToFreePlan : false)
    if(userHasAccess){
      onContentSelected({
        moduleId: moduleId,
        moduleName: title,
        article: article,
      })
    }else{
      dispatch(openBecomePremiumModal())
    }
  }

  return (
    <Accordion style={containerStyle} onChange={(e, expanded) => openModuleAccordion(moduleId, expanded)}>
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
          }}
        >
          <div
            style={{
              display: "flex",
              textAlign: "start",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "26px",
                color: "#66707A",
                marginBottom: "5px",
              }}
            >
              {order}. Modül
            </Typography>
            <img src={ModuleAccordionDownArrow} alt="" />
          </div>

          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "700",
              fontSize: "24px",
              lineHeight: "32px",
              color: "#0D0C0F",
              marginBottom: "8px",
            }}
          >
            {title}
          </Typography>
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: "#66707A",
            }}
          >
            {description}
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Stepper
          activeStep={1}
          orientation="vertical"
          connector={<ColorlibConnector />}
        >
          {articles.length > 0 && articles.map((step, index) => (
            <Step style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}} key={step.id}>
              <StepLabel StepIconComponent={() => ColorlibStepIcon(step, index)}>
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "28px",
                    color: "#0D0C0F",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    onContentClicked(
                      moduleId,
                      title,
                      step,
                    )
                  }
                >
                  {index + 1 + "- " + step.name}
                </Typography>
              </StepLabel>
              {
                doesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, step?.showToFreePlan ? step.showToFreePlan : false) ? null : <img src={Lock} alt="" />
              }
            </Step>
          ))}
        </Stepper>
      </AccordionDetails>
    </Accordion>
  );
};

export default ParentSchoolModuleItem;

import { FC, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  CommonLoading,
  ParentSchoolModuleItem,
  ParentSchoolModuleProgress,
  ParentSchoolVideoContentHeader,
  VideoPlayer,
} from "../components";
import { getAllParentSchoolContent } from "../redux/parentSchoolSlice";
import { IParentSchoolPageSelectedContent, IParentSchoolSelectedArticle } from "../utils/commonModels";
import { Buckets } from "../utils/constants";
import { ArticleType, OpenAPI, ProgressLogItemType, ProgressLogNameType, TrainingSetArticleItem } from "../client";
import useGoogleReCaptchaV3 from "../utils/googleReCaptchaV3.ts/useGoogleReCaptchaV3";
import { doesUserHasAccessToContent, getVideoUrl } from "../utils/commonFuncs";
import InfiniteScroll from "react-infinite-scroll-component";
import parse from "html-react-parser";
import { getArticle } from "../redux/articleSlice";
import { addProgress } from "../redux/logSlice";
import { IArticle } from "../apiClient/models/article";
import { IParentSchoolWithArticle } from "../apiClient/models/parentSchool";
import { GetParentSchoolModuleArticles } from "../apiClient/services";

const ParentSchool: FC = () => {

  const dispatch = useDispatch<AppDispatch>();
  const { loading, parentSchool, error } = useSelector(
    (state: RootState) => state.parentSchool
  );
  const {  profile } = useSelector((state: RootState) => state.auth);
  const {single_article, loading:article_loading} = useSelector((state:RootState) => state.article)

  const [selected, setSelected] = useState<IParentSchoolSelectedArticle | null>(null);
  const [modules, setModules] = useState<IParentSchoolWithArticle[]>([]);

  const DATA_LENGTH_LIMIT = 30

  async function fetchModule(id:number):Promise<IArticle[]> {
    const currentModule = await GetParentSchoolModuleArticles(id)
    return currentModule.articles
  }

  async function getModuleDetail(moduleId:number):Promise<IArticle[]> {
    const moduleArticles = await fetchModule(moduleId)
    setModules( modules => [...modules, {id:moduleId, articles:moduleArticles}])
    return moduleArticles
  }

  useEffect(() => {
    if (parentSchool.data.length == 0) {
      dispatch(
        getAllParentSchoolContent({
          perPage:DATA_LENGTH_LIMIT,
          page:1,
          name:""
        })
      );
    }
  }, [parentSchool.data]);

  useEffect(() => {
    if (
      parentSchool.data.length > 0 && modules.length == 0
    ) {
      getModuleDetail(parentSchool.data[0].id).then(e => {
        setSelected({
          moduleId: parentSchool.data[0].id.toString(),
          moduleName: "1. Modül",
          article: e[0],
        })
      }).catch()
      
    }
  }, [parentSchool.data]);
  
  const loadMore = async () => {
    try {
      let currentYPosition = 0;
      try {
        currentYPosition = window.pageYOffset;
      } catch (error) {}

      if (parentSchool.data.length < parentSchool.total && !loading) {
        await dispatch(
          getAllParentSchoolContent({
            perPage:DATA_LENGTH_LIMIT,
            page: parentSchool.currentPage + 1,
            name:""
          })
        );
      }
      window.scrollTo(0, currentYPosition);
    } catch (error) {
      console.log(error)
    }
  };

  const contentEndCallBack = () => {
    try {
      const modulesDuplicated = parentSchool.data
      const currentModuleIndex = modulesDuplicated.findIndex((e)=> e.id.toString() == selected?.moduleId)

      if(modulesDuplicated.length - 1 >= currentModuleIndex){
        const currentModule = modules.find((e) => e.id.toString() == selected?.moduleId) as IParentSchoolWithArticle
        const contentIndex = currentModule?.articles.findIndex(e => e.id == selected?.article.id)

        let userHasAccess = doesUserHasAccessToContent(profile?.is_user_premium ? profile.is_user_premium : false, currentModule.articles[contentIndex + 1]?.showToFreePlan as boolean)

        if(currentModule.articles.length -1 > contentIndex && userHasAccess){
          selectNewContent({
            moduleId:currentModule.id.toString(),
            moduleName:modulesDuplicated[currentModuleIndex].name,
            article: currentModule.articles[contentIndex + 1]
          })
        }
      }
    } catch (error) {
      console.log("contentEndCallBack",error)
    }
  }

  const selectNewContent = (item: IParentSchoolSelectedArticle) => {
    try {
      setSelected(item)

      const currentModule = parentSchool.data.find((e) => e.id.toString() == item.moduleId)

      dispatch(addProgress(
        {
          progressableType:"Article",
          progressableId: item.article.id.toString(),
          status:"watched"
        }
      ))
      item.article.isWatched = true
      setSelected(item)
      
    } catch (error) {
      console.log("selectNewContent",error)
    }
  }

  const openModuleAccordion = async(moduleId:string, isExpanded:boolean) => {
    const hasModule = modules.find((k) => k.id == Number(moduleId))
    if(isExpanded && !hasModule) {
      await getModuleDetail(Number(moduleId))
    }
  }

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading || article_loading} />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        columnSpacing={1}
        rowSpacing={1}
      >
        <Grid item xs={4} sm={8} md={9}>
          {selected != null && selected.article.type == ArticleType.VIDEO ? (
            <VideoPlayer
              url={`${Buckets.ArticleVideoBucket}/${selected.article.id}.mp4`}
              thumbnailUrl={
                selected
                  ? `${Buckets.ArticleImageBucket}/${selected.article.id}.jpg`
                  : ""
              }
              contentEndCallBack={contentEndCallBack}
            />
          ) : (
            <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "33px",
                  color: "#000000",
                  textAlign: "start",
                }}
              >
                {parse(single_article.textContent ? single_article.textContent : "")}
              </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={false}
          sm={false}
          md={3}
          sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
        >
          <ParentSchoolModuleProgress />
        </Grid>
        <Grid item xs={4} sm={8} md={9}>
          {selected && (
            <ParentSchoolVideoContentHeader
              contentId={selected?.article.id.toString()}
              title={selected != null ? selected.article.name : ""}
              parentSolutionName={selected != null ? selected.moduleName : ""}
              parentSchoolModuleId={selected?.moduleId}
            />
          )}
        </Grid>
        <Grid item xs={4} sm={8} md={12}>
          <InfiniteScroll
            dataLength={parentSchool.data.length}
            next={() => loadMore()}
            hasMore={parentSchool.data.length === parentSchool.total ? false : true}
            loader={null}
          >
            {!loading &&
              parentSchool.data.map((e, index) => (
                <ParentSchoolModuleItem
                  order={index + 1}
                  title={e.name}
                  description={e.shortDescription}
                  articles={modules.length > 0 ? modules.find((k) => k.id == e.id)?.articles as IArticle[] : []}
                  selectedItem={selected}
                  moduleId={e.id.toString()}
                  onContentSelected={(item: IParentSchoolSelectedArticle) =>
                    selectNewContent(item)
                  }
                  openModuleAccordion={openModuleAccordion}
                />
              ))}
          </InfiniteScroll>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ParentSchool;

import { AxiosResponse } from "axios";
import { IDailyQuote, IDailyQuoteData } from "../models/dailyQuote";
import { contentClient } from "../apiClient";

export const GetRandomDailyQuote = async(): Promise<IDailyQuoteData<IDailyQuote>> => {
    try {
        const response: AxiosResponse<IDailyQuoteData<IDailyQuote>> = await contentClient().get('/dailyQuote/random');
        return response.data;
    } catch (error) {
        console.error('Error fetching daily quote:', error);
        throw new Error('Failed to fetch daily quote');
    }
}
